import React from 'react';


function Banner(props) {

   
    return (
        <>
            <div id="sc_banner"  >
                <div className=' height100 d-flex justify-content-center align-items-center'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="inner height100 d-flex align-items-center justify-content-center">
                                    <div className="inner">
                                        <h5>Welcome to</h5>
                                        <h1>KingKong Realm</h1>
                                        <p>A unique Game-Fi project on the BSC network! KingKong Realm is not just a game; it's a fantastic experience focused on community consensus and innovative integration from the world of Game and Finance (Game-Fi) on the blockchain.</p>
                                        <div className="notlogin">
                                            <h3 className='txt-tb2'>Announcement: The KingKong Realm (KKR) token will be listed on the exchange on October 19, 2024. The listing price will be $0.4.</h3>
                                            <h4 className='txt-tb'>Buy early to get preferential pricing before the token goes public.</h4>
                                            <a type='button' className='btn-buy-now-a' href="#sc_presale">Buy now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="bn-img">
                                    <img src="images/i-bn.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </>
    );
}

export default Banner;